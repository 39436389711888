import * as React from 'react'
import cx from 'classnames'
import { IconProps } from '@toasttab/buffet-pui-icons'

export interface FilledBackgroundIconProps extends IconProps {
  /* Icon to render */
  icon: React.ReactNode
  /* Pass the background color to apply a filled background */
  className: string
  /* Test Id */
  testId: string
}

export const FilledBackgroundIcon = ({
  icon,
  className,
  testId
}: FilledBackgroundIconProps) => {
  return (
    <div
      data-testid={testId}
      className={cx(
        'w-10 h-10 rounded-md flex items-center justify-center',
        className
      )}
    >
      {icon}
    </div>
  )
}
