import * as React from 'react'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { MobileDownloadPage } from './MobileDownloadPage/MobileDownloadPage'
import { DesktopDownloadPage } from './DesktopDownloadPage/DesktopDownloadPage'
import { useSearchParams } from 'react-router-dom'

export const ToastNowDownloadPage = () => {
  const [URLSearchParams] = useSearchParams()
  const screenSize = useScreenSize()

  const isMobile = screenSize < ScreenSize.MD
  const loginUrl = URLSearchParams.get('loginUrl') || ''

  return isMobile ? (
    <MobileDownloadPage loginUrl={loginUrl} />
  ) : (
    <DesktopDownloadPage loginUrl={loginUrl} />
  )
}
