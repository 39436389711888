import * as React from 'react'
import { Header, Page } from '@toasttab/buffet-pui-wizard-templates'
import {
  TOAST_NOW_APP_HUB_DESCRIPTION,
  TOAST_NOW_APP_HUB_HEADER,
  TOAST_NOW_APP_HUB_IMG,
  TOAST_NOW_APP_MANAGE_DESCRIPTION,
  TOAST_NOW_APP_MANAGE_HEADER,
  TOAST_NOW_APP_MANAGE_IMG,
  TOAST_NOW_APP_QR_CODE_DESCRIPTION,
  TOAST_NOW_APP_QR_CODE_HEADER,
  TOAST_NOW_APP_QR_CODE_IMG
} from '../constants'
import { ToastNowItem, ToastWebLoginButton } from '../shared'

export interface DesktopDownloadPageProps {
  testId?: string | number
  loginUrl?: string
}

export const DesktopDownloadPage = ({
  testId = 'desktop-download-page',
  loginUrl
}: DesktopDownloadPageProps) => {
  return (
    <Page testId={testId}>
      <Header
        logoRelativeLink={{ link: '', label: 'No navigation on click' }}
      />
      <div className='flex flex-col items-center px-40 py-16 overflow-auto'>
        <h1 className='py-16 text-center type-headline-1'>
          Download the Toast Now mobile app
        </h1>
        <div className='grid w-full grid-cols-3 gap-6'>
          <ToastNowItem
            testId={`${testId}-tn-hub`}
            image={{ url: TOAST_NOW_APP_HUB_IMG, alt: 'tn-hub-image' }}
            header={TOAST_NOW_APP_HUB_HEADER}
            description={TOAST_NOW_APP_HUB_DESCRIPTION}
          />
          <ToastNowItem
            testId={`${testId}-tn-manage`}
            image={{ url: TOAST_NOW_APP_MANAGE_IMG, alt: 'tn-manage-image' }}
            header={TOAST_NOW_APP_MANAGE_HEADER}
            description={TOAST_NOW_APP_MANAGE_DESCRIPTION}
          />
          <ToastNowItem
            testId={`${testId}-tn-qr-code`}
            image={{ url: TOAST_NOW_APP_QR_CODE_IMG, alt: 'tn-qr-code-image' }}
            header={TOAST_NOW_APP_QR_CODE_HEADER}
            description={TOAST_NOW_APP_QR_CODE_DESCRIPTION}
          />
        </div>
        <ToastWebLoginButton
          testId={`${testId}-toastweb-login-button`}
          loginUrl={loginUrl}
        />
      </div>
    </Page>
  )
}
