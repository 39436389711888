import * as React from 'react'
import { CloseIcon } from '@toasttab/buffet-pui-icons'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { Image } from '@toasttab/buffet-pui-image'
import { TOAST_NOW_ICON } from './assets'
import { useDetermineBannerVisibility } from './Hooks'

export interface ToastNowSmartAppBannerProps {
  testId?: string
}

/**
 * ToastNowSmartAppBanner component.
 */
export const ToastNowSmartAppBanner = ({
  testId = 'toast-now-smart-app-banner'
}: ToastNowSmartAppBannerProps) => {
  const [isVisible, toastNowInstallBannerRedirectURL, dismissBanner] =
    useDetermineBannerVisibility()

  return (
    isVisible && (
      <div
        className='w-full top-0 py-3 bg-primary-0 bg-transparent z-50'
        data-testid={testId}
        style={{ backgroundColor: '#F2F1F6' }}
      >
        <div className='w-full h-16 top-0 flex items-center'>
          <div className='flex-0 justify-self-start'>
            <IconButton
              testId={`${testId}-close-button`}
              cropToIcon={false}
              className='place-self-center mt-1'
              iconColor='secondary'
              icon={<CloseIcon aria-label='close' />}
              onClick={dismissBanner}
            />
          </div>
          <button
            className='flex-1 flex text-left h-full border-none'
            onClick={() => {
              redirectToAppStore(toastNowInstallBannerRedirectURL)
            }}
          >
            <Image
              testId={`${testId}-icon`}
              src={TOAST_NOW_ICON}
              alt='toast now icon'
              containerClassName='w-16 h-16'
            />
            <div className='flex-1 pl-2'>
              <Title />
            </div>
          </button>
          <Button
            testId={`${testId}-view-button`}
            as='a'
            variant='link'
            href={toastNowInstallBannerRedirectURL}
          >
            View
          </Button>
        </div>
      </div>
    )
  )
}

const Title = () => {
  return (
    <div className='flex-col justify-between'>
      <p className='type-default font-medium'>Toast Now</p>
      <p className='type-subhead text-secondary'>Toast, Inc.</p>
      <p className='type-subhead text-secondary pt-0.5'>
        Get it on the App Store
      </p>
    </div>
  )
}

function redirectToAppStore(redirectURL: string) {
  window.location.assign(redirectURL)
}
