import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { resolveRedirectUrl } from './Util'
import React from 'react'

export const TOAST_NOW_BANNER_STORAGE_KEY =
  'toast-now-install-banner-session-count'
export const TOAST_NOW_BANNER_MAX_SESSIONS = 100

export function useDetermineBannerVisibility(): [boolean, string, () => void] {
  const redirectURL: string = resolveRedirectUrl()
  const isMobile = useScreenSize() < ScreenSize.MD

  const [isVisible, setVisible] = React.useState<boolean>(
    (redirectURL ? true : false) && isMobile && isBannerEnabledViaPreferences()
  )

  const dismissBanner = () => {
    updateLocalStoragePreference(TOAST_NOW_BANNER_MAX_SESSIONS + 1)
    setVisible(false)
  }

  return [isVisible, redirectURL, dismissBanner]
}

function updateLocalStoragePreference(value: number) {
  localStorage.setItem(TOAST_NOW_BANNER_STORAGE_KEY, value.toString())
}

function isBannerEnabledViaPreferences(): boolean {
  const toastNowHeaderSessionCountFromStorage = parseInt(
    localStorage.getItem(TOAST_NOW_BANNER_STORAGE_KEY) || '0'
  )
  updateLocalStoragePreference(toastNowHeaderSessionCountFromStorage + 1)

  return toastNowHeaderSessionCountFromStorage < TOAST_NOW_BANNER_MAX_SESSIONS
}
