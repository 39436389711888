/**
 * Toast Now support hub
 */
export const TOAST_NOW_APP_HUB_IMG =
  'https://cdn.toasttab.com/static/6d4a8c5e3f51d8c9416b48790c6bb862068365d2/images/onboarding_journey/toast_now_app/toast_now_hub.svg'
export const TOAST_NOW_APP_HUB_HEADER = 'Go-to setup & support hub'
export const TOAST_NOW_APP_HUB_DESCRIPTION =
  'Manage your setup, send messages, schedule meetings, and stay on track with timely notifications from our onboarding team. '

/**
 * Toast Now manage
 */
export const TOAST_NOW_APP_MANAGE_IMG =
  'https://cdn.toasttab.com/static/6d4a8c5e3f51d8c9416b48790c6bb862068365d2/images/onboarding_journey/toast_now_app/toast_now_manage.svg'
export const TOAST_NOW_APP_MANAGE_HEADER = 'Manage on the go'
export const TOAST_NOW_APP_MANAGE_DESCRIPTION =
  'See your most important metrics at a glance, quickly throttle takeout & delivery channels and much more.'

/**
 * Toast Now download QR code
 */
export const TOAST_NOW_APP_QR_CODE_IMG =
  'https://cdn.toasttab.com/static/6d4a8c5e3f51d8c9416b48790c6bb862068365d2/images/onboarding_journey/toast_now_app/toast_now_padding_qr_code.svg'
export const TOAST_NOW_APP_QR_CODE_HEADER = 'Scan the QR code to download '
export const TOAST_NOW_APP_QR_CODE_DESCRIPTION =
  'Set up and manage your operation on the go using the Toast Now mobile app. '

export const APP_STORE_BADGE =
  'https://cdn.toasttab.com/static/9e4befde8515783a51eb58e4b665b56ecf1dff7c/images/onboarding_journey/toast_now_app/app_store_badge.svg'

export const APP_STORE_URL =
  'https://apps.apple.com/us/app/toast-now/id6444586410'
