import React from 'react'
import cx from 'classnames'
import { Image } from '@toasttab/buffet-pui-image'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { FilledBackgroundIcon } from './FilledBackgroundIcon'

export interface ToastNowItemProps {
  testId?: string | number
  icon?: React.ReactNode
  image?: {
    url: string
    alt: string
  }
  header: string
  description: string
}

export const ToastNowItem = ({
  testId = 'toast-now-item',
  icon,
  image,
  header,
  description
}: ToastNowItemProps) => {
  const screenSize = useScreenSize()
  const isMobile = screenSize < ScreenSize.MD

  return (
    <div
      data-testid={testId}
      className={cx('flex w-full', {
        'flex-row gap-3 items-start': isMobile,
        'flex-col gap-6 items-center': !isMobile
      })}
    >
      {isMobile && !!icon && (
        <FilledBackgroundIcon
          testId={`${testId}-icon`}
          className='bg-gray-25'
          icon={icon}
        />
      )}
      {!isMobile && !!image && (
        <Image
          testId={`${testId}-image`}
          src={image.url}
          alt={image.alt}
          containerClassName='w-full object-fill'
        />
      )}
      <DescriptionContent
        header={header}
        description={description}
        isMobile={isMobile}
      />
    </div>
  )
}

interface DescriptionContentProps {
  header: string
  description: string
  isMobile: boolean
}
const DescriptionContent = ({
  header,
  description,
  isMobile
}: DescriptionContentProps) => {
  return (
    <div
      className={cx('flex flex-col items-start w-full', {
        'gap-3': isMobile,
        'gap-5': !isMobile
      })}
    >
      <p
        className={cx({
          'type-subhead font-semibold': isMobile,
          'type-headline-4': !isMobile
        })}
      >
        {header}
      </p>
      <p
        className={cx({
          'type-subhead text-secondary': isMobile,
          'type-default': !isMobile
        })}
      >
        {description}
      </p>
    </div>
  )
}
