export const APP_STORE_URL =
  'https://apps.apple.com/us/app/toast-now/id6444586410'

export function resolveRedirectUrl(): string {
  const userAgent = navigator.userAgent

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return ''
  }

  /**
   * Xinyi S: Let's add code to check Android user agent when we are able to support Mobile Onboarding for Android devices.
   * Google play URL: 'https://play.google.com/store/apps/details?id=com.toasttab.toastoperator'
   */

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return APP_STORE_URL
  }
  return ''
}
