import { ErrorResponse } from './ErrorResponse'
import { SuccessResponse } from './SuccessResponse'

interface Options {
  method?: string
  body?: Object
  headers?: Record<string, string>
}

export async function request(
  url: string,
  options: Options = {},
  headers?: Record<string, string>
): Promise<any> {
  const { body, ...otherOptions } = options
  let response = await fetch(url, {
    ...otherOptions,
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      'content-type': 'application/json',
      ...headers
    }
  })

  if (!response.ok) {
    throw new ErrorResponse(
      `Error encountered while attempt to retrieve invitation url`,
      await response.text(),
      response.status
    )
  }

  return new SuccessResponse(await response.text(), response.status)
}

/**
 * Currently, we only need the GET request in this SPA. However, please add DELETE, POST, PUT, and PATCH
 * if they are needed in the future.
 */
export const http = {
  get<T = undefined>(
    url: string,
    headers?: Record<string, string>
  ): Promise<T> {
    return request(url, {}, headers)
  }
}
