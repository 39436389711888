import React from 'react'
import { KitchenIllustration } from '@toasttab/buffet-pui-illustrations'
import { Header, Page } from '@toasttab/buffet-pui-wizard-templates'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'

interface ErrorPageProps {
  isEnterprise?: boolean
}

export const ErrorPage = ({ isEnterprise = false }: ErrorPageProps) => {
  const screenSize = useScreenSize()
  const isMobile = screenSize < ScreenSize.MD

  return (
    <Page testId='error-page'>
      <Header
        logoRelativeLink={{ link: '', label: 'No navigation on click' }}
      />
      <div className='flex flex-col items-center h-full px-4 pt-20 space-y-6 bg-white md:px-0'>
        <div
          className='pt-2 md:pt-6'
          style={!isMobile ? { paddingInline: '270px' } : {}}
        >
          <KitchenIllustration
            className='w-full h-full px-10 md:px-0'
            testId='kitchen-illustration'
          />
        </div>
        <h2 className='text-center type-headline-3 md:type-headline-2 text-default'>
          Welcome to the Toast family!
        </h2>
        {!isEnterprise && (
          <p className='text-center type-default text-default'>
            We’ll send your implementation contact a welcome email to create an
            account and get started with setup.
          </p>
        )}
      </div>
    </Page>
  )
}
