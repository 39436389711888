export class ErrorResponse extends Error {
  readonly data: string | null
  readonly status: number

  constructor(message: string, data: string | null, status: number) {
    super(message)
    this.data = data
    this.status = status
  }
}
