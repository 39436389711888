import * as React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {
  BASE_URL,
  ToastNowDownloadPage,
  WelcomePage
} from '@local/pre-login-onboarding'

export function App() {
  const queryClient = new QueryClient()

  return (
    <BrowserRouter basename={BASE_URL}>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path='/download-tn' element={<ToastNowDownloadPage />} />
          <Route path='/*' element={<WelcomePage />} />
        </Routes>
      </QueryClientProvider>
    </BrowserRouter>
  )
}
