import React, { useState } from 'react'
import { ErrorPage, LoadingPage } from './StatusPages'
import { useSearchParams } from 'react-router-dom'
import { useGetInvitationUrl } from '@local/pre-login-onboarding-api'
import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'

/**
 * DCHU: restaurant creation takes notably longer in test environments
 *   production    =  24 attempts * 5 seconds =  2 minutes
 *   preproduction = 120 attempts * 5 seconds = 10 minutes
 */
const MAX_RETRY_ATTEMPTS =
  getCurrentEnvironment() === ToastEnvironment.PROD ? 24 : 120
const POLL_DELAY = 5000

export const WelcomePage = () => {
  const [URLSearchParams] = useSearchParams()
  const collectionId = URLSearchParams.get('cid')
  const envelopeId = URLSearchParams.get('eid')

  const [retryAttempt, setRetryAttempt] = useState(0)

  const { data, error, isLoading, refetch } = useGetInvitationUrl(
    collectionId,
    envelopeId,
    {
      onSettled: (res, err) => {
        if (
          retryAttempt < MAX_RETRY_ATTEMPTS &&
          (res?.status === 202 || err?.status === 500) // Retry on these status codes only
        ) {
          setRetryAttempt(retryAttempt + 1)
          setTimeout(refetch, POLL_DELAY)
        }
      }
    }
  )

  if (isLoading) {
    return <LoadingPage />
  }

  if (error) {
    switch (error.status) {
      case 422:
        return (
          <>
            {window.location.replace(`${window.location.origin}/${error.data}`)}
          </>
        )
      case 424:
      default:
        return <ErrorPage />
      case 427:
        return <ErrorPage isEnterprise={true} />
    }
  }

  if (data) {
    switch (data.status) {
      case 200:
        return <>{window.location.replace(`${data.data}`)}</>
      case 202:
      default:
        if (retryAttempt < MAX_RETRY_ATTEMPTS) {
          return <LoadingPage />
        } else {
          return <ErrorPage />
        }
    }
  }

  return <></>
}
