import React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'

export interface ToastWebLoginButtonProps {
  testId?: string | number
  loginUrl?: string
}

export const ToastWebLoginButton = ({
  testId = 'toastweb-login-button',
  loginUrl
}: ToastWebLoginButtonProps) => {
  const homepageUrl = window.location.origin.concat('/restaurants/admin/home')

  return (
    <Button
      testId={testId}
      as='a'
      href={!!loginUrl ? loginUrl : homepageUrl}
      variant='link'
      className='py-10 text-link'
    >
      Log in to Toast Web instead
    </Button>
  )
}
