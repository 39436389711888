import * as React from 'react'
import { Header } from '@toasttab/buffet-pui-wizard-templates'
import {
  ArrowCircleUpIcon,
  ReportingAnalyticsIcon,
  RestaurantSupportIcon
} from '@toasttab/buffet-pui-icons'
import { Image } from '@toasttab/buffet-pui-image'
import {
  APP_STORE_BADGE,
  APP_STORE_URL,
  TOAST_NOW_APP_HUB_DESCRIPTION,
  TOAST_NOW_APP_HUB_HEADER,
  TOAST_NOW_APP_MANAGE_DESCRIPTION,
  TOAST_NOW_APP_MANAGE_HEADER
} from '../constants'
import { ToastNowItem, ToastWebLoginButton } from '../shared'
import { ToastNowSmartAppBanner } from '@local/toast-now-install-banner'

export interface MobileDownloadPageProps {
  testId?: string | number
  loginUrl?: string
}

export const MobileDownloadPage = ({
  testId = 'mobile-download-page',
  loginUrl
}: MobileDownloadPageProps) => {
  return (
    <div
      data-testid={testId}
      className='flex flex-col min-h-screen pr-0 bg-white md:bg-page overflow-auto lg:overflow-unset fixed inset-0'
    >
      <ToastNowSmartAppBanner />
      <Header
        logoRelativeLink={{ link: '', label: 'No navigation on click' }}
      />
      <div className='flex flex-col items-center px-4 pt-10 pb-20 overflow-auto'>
        <div
          data-testid={`${testId}-header`}
          className='flex flex-col items-center gap-6 px-3 py-6'
        >
          <ArrowCircleUpIcon
            aria-label='arrow circle up icon'
            size='xl'
            className='font-normal text-default'
          />
          <h1 className='text-center type-headline-3'>
            Download the Toast now mobile app to continue onboarding
          </h1>
        </div>
        <div className='flex flex-col w-full gap-6 pt-6'>
          <ToastNowItem
            testId={`${testId}-tn-hub`}
            icon={
              <RestaurantSupportIcon
                accessibility='decorative'
                className='text-secondary'
              />
            }
            header={TOAST_NOW_APP_HUB_HEADER}
            description={TOAST_NOW_APP_HUB_DESCRIPTION}
          />
          <ToastNowItem
            testId={`${testId}-tn-manage`}
            icon={
              <ReportingAnalyticsIcon
                accessibility='decorative'
                className='text-secondary'
              />
            }
            header={TOAST_NOW_APP_MANAGE_HEADER}
            description={TOAST_NOW_APP_MANAGE_DESCRIPTION}
          />
        </div>
        <div className='pt-10'>
          <a
            data-testid={`${testId}-app-store-badge-link`}
            href={APP_STORE_URL}
          >
            <Image
              testId={`${testId}-app-store-badge`}
              src={APP_STORE_BADGE}
              alt='app store badge'
            />
          </a>
        </div>
        <ToastWebLoginButton
          testId={`${testId}-toastweb-login-button`}
          loginUrl={loginUrl}
        />
      </div>
    </div>
  )
}
