import {
  UseQueryOptions,
  UseQueryResult,
  useQuery
} from '@tanstack/react-query'
import { ErrorResponse, SuccessResponse, http } from '@local/http-client'

export const useGetInvitationUrl = (
  collectionId: string | null,
  envelopeId: string | null,
  options: Omit<
    UseQueryOptions<{}, ErrorResponse, SuccessResponse, string[]>,
    'queryKey' | 'queryFn' | 'initialData'
  >
): UseQueryResult<SuccessResponse | null, ErrorResponse | undefined> => {
  if (!collectionId && !envelopeId) {
    throw new Error('One of CID or EID is required but neither was not found')
  }

  return useQuery(
    ['getInvitationUrl'],
    async () =>
      http.get(
        `/api/service/pre-login-onboarding/v1/account-activation/${
          collectionId
            ? `collectionId/${collectionId}`
            : `envelopeId/${envelopeId}`
        }`
      ),
    {
      enabled: !!collectionId || !!envelopeId,
      refetchOnWindowFocus: false,
      retry: false,
      ...options
    }
  )
}
