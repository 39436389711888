import React from 'react'
import { Header, Page } from '@toasttab/buffet-pui-wizard-templates'
import Lottie from 'lottie-react'
import AnimatedCreationIllustration from './AnimatedCreationIllustration.json'

export const LoadingPage = () => {
  return (
    <Page testId='loading-page'>
      <Header
        logoRelativeLink={{ link: '', label: 'No navigation on click' }}
      />
      <div className='flex flex-col items-center h-full px-4 space-y-6 overflow-auto bg-white pt-28 md:px-0 md:pt-0 md:place-content-center'>
        <Lottie
          data-testid='animated-creation-illustration'
          animationData={AnimatedCreationIllustration}
          loop={true}
        />
        <h2
          className='text-center type-headline-3 md:type-headline-2 text-default'
          data-testid='text-main'
        >
          You’re on your way!
          <br />
          Next, we’ll create your Toast account
        </h2>
        <p className='text-center type-default text-default'>
          You’ll be redirected in 1-2 minutes, please don’t leave or refresh
          this page.
        </p>
      </div>
    </Page>
  )
}
